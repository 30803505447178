import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import grey from '@mui/material/colors/grey';

// const useStyles = makeStyles((theme) => ({
//   popover: {
//     pointerEvents: 'none',
//   },
//   paper: {
//     padding: theme.spacing(1),
//   },
//   buttonText: {
//     color: grey[50]
//   },
//   apolloButton: {
//     marginRight: 10,
//   },
//   linkButton: {
//     padding: 0,
//     margin: 0
//   }
// }));

function ListItemLink(props) {
  // const classes = useStyles();
  return <ListItem button component={Link} {...props}/>;
}

export default function ApolloTree(props) {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      handlePopoverClose()
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        onClick={handlePopoverOpen}
        onClose={handlePopoverClose}
        style={{color: 'white'}}
      >
        <Typography style={{textTransform: 'none', fontSize: "2 rem", fontWeight: 'bold'}}>
          {props.title}
        </Typography>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List disablePadding={true}>
          <ListItemLink to={"/" + props.path + "/month"} onClick={handlePopoverClose}>
            <ListItemText primary="Monthly Data"/>
          </ListItemLink>
          <ListItemLink to={"/" + props.path + "/day"} onClick={handlePopoverClose}>
            <ListItemText primary="Daily Data"/>
          </ListItemLink>
        </List>
      </Popover>
    </div>
  );
}
