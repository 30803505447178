import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, } from 'react-router-dom';
import Home  from './components/Home';
import GraphMonth from './components/GraphMonth';
import GraphDay from './components/GraphDay';
import UltimaBar from './components/UltimaBar';

function App() {
    return (
        <BrowserRouter>
            <UltimaBar />
            <Switch>
                <Route exact path ="/">
                    <Home />
                </Route>
                <Route exact path = "/apollo12/day">
                    <GraphDay
                        table={"apollo12int"}
                        title={"Apollo 12 Magnetometer Daily Data, 11/19/1969 - 04/03/1970"}
                        startingDate={'1969-11-19 PST'}
                        maxDate={'1970-04-03 PST'}
                    />
                </Route>
                <Route exact path = "/apollo12/month">
                    <GraphMonth 
                        table={"apollo12month"}
                        title={"Apollo 12 Magnetometer Monthly Data, 11/19/1969 - 04/03/1970"}
                        startingDate={'1969-11-1 PST'}
                        maxDate={'1970-05-01 PST'}
                    />
                </Route>
                <Route exact path = "/apollo15/day">
                    <GraphDay
                        table={"apollo15int"}
                        title={"Apollo 15 Magnetometer Data, 07/31/1971 - 09/20/1972"}
                        startingDate={'1971-7-31 PST'}
                        maxDate={'1972-09-20 PST'}
                    />
                </Route>
                <Route exact path = "/apollo15/month">
                    <GraphMonth 
                        table={"apollo15month"}
                        title={"Apollo 15 Magnetometer Data, 07/31/1971 - 09/20/1972"}
                        startingDate={'1971-7-1 PST'}
                        maxDate={'1972-09-01 PST'}
                    />
                </Route>
                <Route exact path = "/apollo16/day">
                    <GraphDay 
                        table={"apollo16int"}
                        title={"Apollo 16 Magnetometer Data, 04/24/1972 - 09/21/1974"}
                        startingDate={'1972-04-24 PST'}
                        maxDate={'1974-09-21 PST'}
                        gapStart={'1973-02-15 PST'}
                        gapEnd={'1973-08-18 PST'}
                    />
                </Route>
                <Route exact path = "/apollo16/month">
                    <GraphMonth 
                        table={"apollo16month"}
                        title={"Apollo 16 Magnetometer Data, 04/24/1972 - 09/21/1974"}
                        startingDate={'1972-04-01 PST'}
                        maxDate={'1974-09-01 PST'}
                        gapStart={'1973-03-01 PST'}
                        gapEnd={'1973-07-01 PST'}
                    />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default App;
