import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import Axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "./customDatePicker.css";
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
// init the module
noDataToDisplay(Highcharts);
Boost(Highcharts);

function GraphMonth(props) {
  const [startDate, setStartDate] = useState(new Date(props.startingDate));
  const [bx, setBx] = useState([]);
  const [by, setBy] = useState([]);
  const [bz, setBz] = useState([]);

  async function getData(table, year, month, day) {
    try {
    const callData = { table, year, month, day };
    await Axios.post("https://apollo-backend-dot-apollo-323315.uc.r.appspot.com/getMagDataMonth", callData).then((response) => {
      console.log(response)
      // response.data.map(entry => {
      // setBx(response.data.map(entry => entry.b_x));
      // setBy(response.data.map(entry => entry.b_y));
      // setBz(response.data.map(entry => entry.b_z));
      let bxArr = response.data.map(entry => entry.b_x);
      let byArr = response.data.map(entry => entry.b_y);
      let bzArr = response.data.map(entry => entry.b_z);

      setBx(bxArr)
      setBy(byArr)
      setBz(bzArr)
      console.log("bx state var", bx);
      console.log("by state var", by);
      console.log("bz state var", bz);
      // response.data.map(entry => {
      //   setBx(bx.push(entry.b_x))
      //   setBy(by.push(entry.b_y))
      //   setBz(bz.push(entry.b_z))
      // })
    })
  }
  catch (err) {
    console.log(err.message)
  }
}

  useEffect(() => {
    setStartDate(new Date(props.startingDate))
  }, [props.startingDate])

  useEffect(() => {
    setBx([])
    setBy([])
    setBz([])
    console.log("STARTING DATE IS", startDate)
    console.log(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`)
    // getData(startDate.getFullYear(), (startDate.getMonth() + 1), startDate.getDate())
    getData(props.table, startDate.getFullYear(), (startDate.getMonth() + 1), startDate.getDate())
  }, [startDate])

  var options = {
    chart: {
      zoomType: 'x',
      height: '50%'
    },
    lang: {
      noData: "Awaiting for data"
    },
    rangeSelector: {
      inputEnabled: false,
      buttons: [
        {
          type: 'all',
          text: '1M',
          title: 'View 1 Month'
        },
        {
          type: 'day',
          count: 1,
          text: '1d',
          title: 'View 1 day'
        },
        {
        type: 'minute',
        count: 10,
        text: '10m',
        title: 'View 10 minutes'
        },
        {
          type: 'minute',
          count: 1,
          text: '1m',
          title: 'View 1 minutes'
        },
        {
          type: 'second',
          count: 30,
          text: '30s',
          title: 'View 30 seconds'
        },
    ]
    },
    title: {
      text: props.title
    },
    xAxis: {
      type: 'datetime'
    },
    // plotOptions: {
    //   pointStart: startDate.getTime(),
    //   pointInterval: 1000,
    // },
    time: {
      timezoneOffset: 8*60
    },
    series: [
      {
        marker: {
          enabled: true,
          radius: 2
        },
        color: 'red',
        name: 'Bx',
        pointStart: startDate.getTime(),
        pointInterval: 30000,
        data: bx // data has to be [x, y] pairs
      },
      {
        marker: {
          enabled: true,
          radius: 2
        },
        color: 'blue',
        name: 'By',
        pointStart: startDate.getTime(),
        pointInterval: 30000,
        data: by
      },
      {
        marker: {
          enabled: true,
          radius: 2
        },
        color: 'green',
        name: 'Bz',
        pointStart: startDate.getTime(),
        pointInterval: 30000,
        data: bz
      }
    ]
  }


  console.log("OPTIONS HERE", options)
  console.log("DATE EXAMPLE", Date('1969-11-19 PST'))

  const isValidDate = (date) => {
    if (props.gapStart === undefined || props.gapStart === null) {
      date.getTime();
      const origDate = new Date(props.startingDate);
      const finalDate = new Date(props.maxDate);
      console.log("GET DATE", date.getTime()) // getting number day of month
      console.log("ORIG DATE", origDate.getTime())
      const bool = origDate.getTime() <= date.getTime() && date.getTime() <= finalDate.getTime();
      console.log("SHOULD SHOW", bool)
      return bool;
    }
    else {
      date.getTime();
      const origDate = new Date(props.startingDate);
      const gapDateStart = new Date(props.gapStart);
      const gapDateEnd = new Date(props.gapEnd);
      const finalDate = new Date(props.maxDate);
      console.log("GET DATE", date.getTime()) // getting number day of month
      console.log("ORIG DATE", origDate.getTime())
      console.log("GAPDATESTART", gapDateStart.getTime())
      console.log("GAPDATEEND", gapDateEnd.getTime())
      const bool = (origDate.getTime() <= date.getTime() && date.getTime() < gapDateStart.getTime()) || (gapDateEnd.getTime() < date.getTime() && date.getTime() <= finalDate.getTime())
      // const bool = origDate.getTime() <= date.getTime() && date.getTime() < gapDateStart.getTime() && gapDateEnd.getTime() < date.getTime() && date.getTime() <= finalDate.getTime();
      console.log(origDate.getTime() <= date.getTime())
      console.log(date.getTime() < gapDateStart.getTime())
      console.log(gapDateEnd.getTime() < date.getTime())
      console.log(date.getTime() <= finalDate.getTime())
      return bool;
    }
  }
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", zIndex: 1, top: 10, right: 5 }}>
        <DatePicker 
          selected={startDate}
          filterDate={isValidDate}
          onChange={(date) => setStartDate(new Date(date.getFullYear(), date.getMonth(), 1))}
          dateFormat="MM/yyyy"
          minDate={new Date(props.startingDate)}
          maxDate={new Date(props.maxDate)}
          showMonthYearPicker
          showTwoColumnMonthYearPicker
        />
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
        />
    </div>
  );
}

// render(<GraphMonth />, document.getElementById('root'));

export default GraphMonth;
